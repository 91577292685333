import { SearchStatusType } from '@/types/enum'
import { isWasmCodeFormat } from '@/app/utils/helper'

export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore()

  if (to.name === 'code-code') {
    if (!to.params.code) {
      appStore.$patch({
        searchStatus: SearchStatusType.WasmCodeInvalid,
        searchParam: to.params.code
      })

      return navigateTo('/404')
    }

    const routeCodeParam = Array.isArray(to.params.code)
      ? to.params.code[0]
      : to.params.code

    if (!isWasmCodeFormat(routeCodeParam)) {
      appStore.$patch({
        searchStatus: SearchStatusType.WasmCodeInvalid,
        searchParam: routeCodeParam
      })

      return navigateTo('/404')
    }
  }
})
